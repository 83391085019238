import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import Badge from '../Badge'
import KismetDropzone from '../DropZone'
import partyjs from "party-js"
import { dateMap, prettyPrintDate } from '../DateHelper'

export const MordorDocumentCrossCheck = ({ questionId, initialEffectiveYear, initialEffectiveMonth, canUploadQuestion }) => {

    const [reportRows, setReportRows] = useState([])
    const [error, setError] = useState(null)
    const [showResolved, setShowResolved] = useState(true)

    const [effectiveMonth, setEffectiveMonth] = useState(initialEffectiveMonth || (new Date()).getMonth()+1)
    const [effectiveYear, setEffectiveYear] = useState(initialEffectiveYear || (new Date().getFullYear()))


    const updateReportRow = (binderItem, propertyId) => {
        let newReportRows = [...reportRows]

        let reportRow = newReportRows.find(rr => rr.property_id == propertyId)
        
        reportRow.document = binderItem.document.url
        reportRow.uploader = binderItem.uploader
        reportRow.effective_date = binderItem.effective_date

        setReportRows(newReportRows)
    }

    const fetchCrosscheckData = async () => {
        const req = axios.get(
            `/api/mordor_binders/document_crosscheck`, {
            params: {
                effective_year: effectiveYear,
                effective_month: effectiveMonth,
                question_id: questionId
            },
            headers: {
                Authorization: `Bearer ${window.react_state.jwt}`
            }
        }
        ).catch(setError)

        const result = await toast.promise(req, { pending: "Working..." })

        setReportRows(result.data)
    }

    useEffect(() => {
        fetchCrosscheckData()
    }, [effectiveMonth, effectiveYear])


    return (
        <div>
            <h1>Mordor Document Cross Check</h1>
            {/* Month Selector */}
            <select value={effectiveMonth} onChange={e => setEffectiveMonth(parseInt(e.target.value))}>
                {Object.entries(dateMap).map(([i, month]) => <option key={i} value={i}>{month}</option>)}
            </select>

            {/* Year Selector */}
            <select value={effectiveYear} onChange={e => setEffectiveYear(parseInt(e.target.value))}>
                <option value={2020}>2020</option>
                <option value={2021}>2021</option>
                <option value={2022}>2022</option>
                <option value={2023}>2023</option>
            </select>

            {/* Checkbox to toggle showResolved*/}
            <label>
                <input type="checkbox" checked={showResolved} onChange={e => setShowResolved(e.target.checked)} />
                Show Resolved Properties
            </label>

            {
                error && <div>Error: {error.message}</div>
            }

            <table className='table table-sm table-hover table-bordered'>
                <thead className='tead-light'>
                    <tr>
                        <th>
                            Property Name
                        </th>
                        <th>
                            MORDOR Binder
                        </th>
                        <th>
                            Document
                        </th>
                        <th>
                            Effective Date
                        </th>
                        <th>
                            Uploader
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {reportRows && reportRows
                        .filter(property => showResolved || !property.document)
                        .map(property => <CrossCheckRow key={property.property_id} questionId={questionId} effectiveMonth={effectiveMonth} effectiveYear={effectiveYear} property={property} canUploadQuestion={canUploadQuestion} onUpload={updateReportRow} />)}
                </tbody>
            </table>

        </div>
    )
}

const CrossCheckRow = ({ property, onUpload, questionId, effectiveMonth, effectiveYear, canUploadQuestion }) => {
    const partyRef = useRef(null)

    const handleRequest = (req) => {
        req.then(result => {
            partyjs.confetti(partyRef.current)

            onUpload(result.body, property.property_id)

        }).catch(e => {
            toast.error("Failed to upload document")
        })

        toast.promise(req, { pending: "Working..." });
    }

    return <tr key={property.property_id}>
        <td>{property.property_name}</td>
        <td><a href={`/properties/${property.property_id}/mordor_binders/${property.binder_id}`}>MORDOR Binder</a></td>
        <td>
            <div ref={partyRef}>
                {property.document
                    ? <Badge success>Yes</Badge>
                    : canUploadQuestion ? <KismetDropzone
                        postUrl="/api/mordor_binders/upload_question"
                        postParams={{
                            question_id: questionId,
                            id: property.binder_id,
                            effective_month: effectiveMonth,
                            effective_year: effectiveYear
                        }}
                        party={true}
                        onRequest={handleRequest}
                    /> : <span />
                }
            </div>
        </td>
        <td>{property.effective_date && prettyPrintDate(property.effective_date)}</td>
        <td>{property.uploader ? property.uploader.first_name + " " + property.uploader.last_name : ""}</td>
    </tr>
}

export default MordorDocumentCrossCheck