import React from 'react'
import MordorProgress from '../mordor/MordorProgress';

const MBPage = ({ property, binder, canUploadQuestion }) => {

    const propertyUrl = `/properties/${property.id}`

    return <div className="justify-content-center">
        <h1 className="text-center"><a href={propertyUrl}>{property.name}</a> - MORDOR Binder</h1>

        <h5>{property.unit_funding_programs.join(" / ")}</h5>
        <h6>Created at {binder.created_at}</h6>

        <MordorProgress binderId={binder.id} expandable={true} canUploadQuestion={canUploadQuestion}/>
    </div>

}

export default MBPage