import React from "react"
import { useDropzone } from 'react-dropzone'
import { useCallback } from 'react'
import styled from 'styled-components'
import SuperAgent from "superagent"
import { toast } from 'react-toastify/dist/react-toastify';


const StyledDiv = styled.div`
    background-color: #fafafa;
    border-radius: 5px;
    border: 2px solid #ccc;
    padding: 20px;
    margin-bottom: 20px;
`

function KismetDropzone({ text = "Drag here or click", draggingText = "Drop the files here...", postUrl = null, onRequest = null, postParams = {}}) {
    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        if (rejectedFiles && rejectedFiles.length) {
            toast.error("Only pdf files can be uploaded");
            return
        }

        if (!postUrl) {
            alert ("posturl required")
        }
        else if (acceptedFiles.length > 1) {
            alert("You can only upload one file at a time")
        } else {
            const file = acceptedFiles[0]
            const req = SuperAgent.post(postUrl)
            
            Object.entries(postParams).forEach(([key, value]) => {
                console.log("pp", key, value)
                req.field(key, value)
            })

            req.set("Authorization", "Bearer " + window.react_state.jwt)
        
            req.attach("file", file)
            if (onRequest) onRequest(req)
        }
    }, [postParams, postUrl, onRequest])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: {
        "application/pdf": [".pdf"],
        "text/csv": [".csv"],
        "text/plain": [".doc", ".docx", ".xlsx"]
    } })

    return (
        <StyledDiv {...getRootProps()}>
            <input {...getInputProps()} />
            {
                !isDragActive ?
                    <p>{text}</p> :
                    <p>{draggingText}</p>
            }
        </StyledDiv>
    )
}

export default KismetDropzone