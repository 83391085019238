import React from "react";

export const ProgressBar = ({
  percent,
  leftText = "",
  rightText = "",
  leftClass = "bg-success",
  rightClass = "bg-warning",
  animated = false,
}) => {
  return (
    <div
      className="progress justify-content-center"
      style={{ height: "25px", width: "100%", margin: "0px 10px" }}
    >
      <div
        className={`progress-bar ${leftClass}`}
        role="progressbar"
        style={{ width: `${percent * 100}%` }}
      >
        {leftText}
      </div>
      <div
        className={`progress-bar ${
          animated ? "progress-bar-striped progress-bar-animated" : ""
        } ${rightClass}`}
        role="progressbar"
        style={{ width: `${(1 - percent) * 100}%` }}
      >
        {rightText}
      </div>
    </div>
  );
};
