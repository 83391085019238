export const sortReportsByStatus = (reportResults) => {

    if (!reportResults) {
        return undefined
    }

    const completedMonthlyDocs = reportResults.monthly_results.filter(q => q.associated_binder_item)
    const completedAnnualDocs = reportResults.annual_results.filter(q => q.associated_binder_item)
    const completedPermanentDocs = reportResults.permanent_results.filter(q => q.associated_binder_item)


    const uncompletedMonthlyDocs = reportResults.monthly_results.filter(q => !q.associated_binder_item)
    const uncompletedAnnualDocs = reportResults.annual_results.filter(q => !q.associated_binder_item)
    const uncompletedPermanentDocs = reportResults.permanent_results.filter(q => !q.associated_binder_item)

    const requiredFilter = q => q.document_required === "Yes"

    const monthlyRequiredCompleted = completedMonthlyDocs.filter(requiredFilter).length
    const annualRequiredCompleted = completedAnnualDocs.filter(requiredFilter).length
    const permanentRequiredCompleted = completedPermanentDocs.filter(requiredFilter).length

    const monthlyRequiredTotal = reportResults.monthly_results.filter(requiredFilter).length
    const annualRequiredTotal = reportResults.annual_results.filter(requiredFilter).length
    const permanentRequiredTotal = reportResults.permanent_results.filter(requiredFilter).length

    const monthlyPercentage = monthlyRequiredCompleted / monthlyRequiredTotal
    const annualPercentage = annualRequiredCompleted / annualRequiredTotal
    const permanentPercentage = permanentRequiredCompleted / permanentRequiredTotal

    return [
        {
            name: "Monthly",
            allDocs: reportResults.monthly_results,
            completedDocs: completedMonthlyDocs,
            uncompletedDocs: uncompletedMonthlyDocs,
            percentage: monthlyPercentage,
            requiredTotal: monthlyRequiredTotal,
            requiredCompleted: monthlyRequiredCompleted
        },
        {
            name: "Annual",
            allDocs: reportResults.annual_results,
            completedDocs: completedAnnualDocs,
            uncompletedDocs: uncompletedAnnualDocs,
            percentage: annualPercentage,
            requiredTotal: annualRequiredTotal,
            requiredCompleted: annualRequiredCompleted
        },
        {
            name: "Permanent",
            allDocs: reportResults.permanent_results,
            completedDocs: completedPermanentDocs,
            uncompletedDocs: uncompletedPermanentDocs,
            percentage: permanentPercentage,
            requiredTotal: permanentRequiredTotal,
            requiredCompleted: permanentRequiredCompleted
        }
    ]
}