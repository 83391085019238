import React, { useState, useEffect, useCallback, useRef } from 'react'
import TreeView from '@mui/lab/TreeView'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import TreeItem from '@mui/lab/TreeItem'
import { useEffectiveDate } from './useEffectiveDate'
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify'
import axios from 'axios'
import { MordorContext } from './mordor/MordorProgress'
import { MordorUploadModal } from './mordor/MordorUploadModal'
import { Skeleton } from '@mui/material'

export default function FileSystemNavigator({ binderIds, canUploadQuestion }) {
  const {
    effectiveYear,
    effectiveMonth,
    effectiveDatePicker,
  } = useEffectiveDate(1, 2022)

  const [reportResults, setReportResults] = useState([])
  const [error, setError] = useState(null)
  const [showResolved, setShowResolved] = useState(true)
  const [crossCheckQuestionId, setCrossCheckQuestionId] = useState(undefined)

  const fetchBinders = async () => {
    setReportResults([])
    const reqs = binderIds.map(async (binderId) => {
      const result = await axios
        .get(`/api/mordor_binders/${binderId}`, {
          params: {
            effective_year: effectiveYear,
            effective_month: effectiveMonth,
          },
          headers: { Authorization: `Bearer ${window.react_state.jwt}` },
        })
        .catch(setError)

      console.log('Result', result)
      // addResults(result.data)
      setReportResults((prev) => [...prev, result.data])
    })

    console.log('starting')
    await toast.promise(Promise.all(reqs), { pending: 'Working...' })
    console.log('done')
  }

  // const addResults = useCallback((results) => {
  //     if (!reportResults) {
  //         setReportResults([results])
  //         return
  //     } else {
  //         let tempReportResults = [...reportResults]
  //         tempReportResults.push(results)
  //         setReportResults(tempReportResults)
  //     }
  // }, [reportResults])

  useEffect(() => {
    fetchBinders()
  }, [effectiveYear, effectiveMonth])

  const generateReportStats = (reportResult) => {
    const is_resolved = q => q.associated_binder_item
    const is_required = q => q.document_required === "Yes"
    // Get portion of report that is not resolved
    const resolved_monthly = reportResult.monthly_results.filter(is_required).filter(is_resolved).length
    const resolved_annual = reportResult.annual_results.filter(is_required).filter(is_resolved).length
    const resolved_permanent = reportResult.permanent_results.filter(is_required).filter(is_resolved).length

    const resolved = resolved_monthly + resolved_annual + resolved_permanent

    // Get total number
    const total_monthly = reportResult.monthly_results.filter(is_required).length
    const total_annual = reportResult.annual_results.filter(is_required).length
    const total_permanent = reportResult.permanent_results.filter(is_required).length
    const total = total_monthly + total_annual + total_permanent

    return {
      resolved_monthly,
      resolved_annual,
      resolved_permanent,
      resolved,
      total_monthly,
      total_annual,
      total_permanent,
      total,
    }
  }

  const [currentBinderId, setCurrentBinderId] = useState(undefined)
  const [currentQuestion, setCurrentQuestion] = useState(undefined)
  const [currentTimeline, setCurrentTimeline] = useState(undefined)
  const [uploadModalOpen, setUploadModalOpen] = useState(false)

  const partyRef = useRef()

  const updateBinderItem = (questionId, timeline, body) => {
    console.log(questionId, timeline, body);
    console.log(currentBinderId, currentQuestion, currentTimeline);

    // Generate new report results with updated question (this is so yucky but will be fixed when we have the api return all results at once)
    const newReportResults = reportResults.map(rr => {
        if (rr.binder_id == currentBinderId) {

            const timelineKey = timeline.toLowerCase() + '_results'

            console.log('timelineKey', timelineKey);

            // Find the correct question
            const newQuestions = rr[timelineKey].map(q => {
                if (q.id === questionId) {
                    console.log('found question');
                    return {...q, associated_binder_item: body}
                } else {
                    return q
                }
            })

            return ({...rr, [timelineKey]: newQuestions})
        } else {
            return rr
        }
    })

    console.log('newReportResults', newReportResults);

    setReportResults(newReportResults)
  }

  const showUploadModalOrOpenDoc = (binderId, question, timeline) => {
    if (question.associated_binder_item) {
        // open doc
        const { url } = question.associated_binder_item.document
        window.open(url, '_blank')
    }
    else if(canUploadQuestion) {
      setCurrentBinderId(binderId)
      setCurrentQuestion(question)
      setCurrentTimeline(timeline)
      setUploadModalOpen(true)
    }
  }

  return (
    <>
      <ToastContainer />
      {effectiveDatePicker}
      {/* turn off dev mode to remove lag https://github.com/mui/material-ui/issues/27403 */}

      <MordorContext.Provider
        value={{
          updateBinderItem,
          binderId: currentBinderId,
          effectiveMonth,
          effectiveYear,
          showCrossCheck: false,
        }}
      >
        {currentBinderId && currentQuestion && currentTimeline && (
          <MordorUploadModal
            mordorQuestion={currentQuestion}
            timeline={currentTimeline}
            partyRef={partyRef}
            uploadModalOpen={uploadModalOpen}
            setUploadModalOpen={setUploadModalOpen}
          />
        )}
      </MordorContext.Provider>

      <TreeView
        aria-label="file system navigator"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        ref={partyRef}
      >
        {reportResults &&
          reportResults.sort((a,b) => a.property_name.localeCompare(b.property_name)).map((reportResult, index) => {
            const {
              resolved_monthly,
              resolved_annual,
              resolved_permanent,
              resolved,
              total_monthly,
              total_annual,
              total_permanent,
              total,
            } = generateReportStats(reportResult)

            return (
              <TreeItem
                key={reportResult.binder_id + "toplevel"}
                nodeId={index + '-0'}
                label={`${reportResult.property_name} (${resolved}/${total})`}
              >
                <TreeItem
                  nodeId={index + '-1'}
                  label={`Monthly (${resolved_monthly}/${total_monthly})`}
                >
                  {reportResult.monthly_results.map((q) => {
                    return (
                      <TreeItem
                        nodeId={index + '-' + q.id}
                        key={index + '-' + q.id}
                        disabled={!q.associated_binder_item}
                        label={q.associated_document}
                        onClick={() =>
                          showUploadModalOrOpenDoc(reportResult.binder_id, q, 'Monthly')
                        }
                      />
                    )
                  })}
                </TreeItem>

                <TreeItem
                  nodeId={index + '-2'}
                  label={`Annual (${resolved_annual}/${total_annual})`}
                >
                  {reportResult.annual_results.map((q) => {
                    return (
                      <TreeItem
                        nodeId={index + '-' + q.id}
                        key={index + '-' + q.id}
                        disabled={!q.associated_binder_item}
                        label={q.associated_document}
                        onClick={() =>
                          showUploadModalOrOpenDoc(reportResult.binder_id, q, 'Annual')
                        }
                      />
                    )
                  })}
                </TreeItem>

                <TreeItem
                  nodeId={index + '-3'}
                  label={`Permanent (${resolved_permanent}/${total_permanent})`}
                >
                  {reportResult.permanent_results.map((q) => {
                    return (
                      <TreeItem
                        nodeId={index + '-' + q.id}
                        key={index + '-' + q.id}
                        disabled={!q.associated_binder_item}
                        label={q.associated_document}
                        onClick={() =>
                          showUploadModalOrOpenDoc(
                            reportResult.binder_id,
                            q,
                            'Permanent',
                          )
                        }
                      />
                    )
                  })}
                </TreeItem>
              </TreeItem>
            )
          })}
      </TreeView>
      {[...Array(binderIds.length - reportResults.length)].map(_ => <Skeleton width='100%'/>)}
    </>
  )
}
