import React, { useState, useRef } from "react"
import KismetDropzone from "./DropZone"
import partyjs from "party-js"

const UploadOrYes = ({ mordorQuestion, postUrl, onBinderItem }) => {
    const [binderItem, setBinderItem] = useState(mordorQuestion['associated_binder_item'])
    const partyRef = useRef()

    const handleUploadResult = (err, result) => {
        if (err) {
            console.warn(err)
            return
        } else {
            setBinderItem(result.body)
            partyjs.confetti(partyRef.current)
            if (onBinderItem) {
                onBinderItem(result.body)
            }
        }
    }

    return <div ref={partyRef}>
        {binderItem
            ? <span className="badge badge-success">Yes</span>
            : <KismetDropzone postUrl={postUrl} party={true} onRequest={handleUploadResult} />
        }
    </div>
}

export default UploadOrYes