import React, { useState } from 'react'
import { v4 as uuid } from 'uuid'

export const KismetAccordion = ({ header, body, expandable }) => {

    const [randomId, _] = useState("KA-" + uuid())

    return <div>
        <div style={{cursor: expandable ? 'pointer' : ''}} data-toggle={expandable ? "collapse" : ""} data-target={"#" + randomId}>
            {header}
        </div>
        <div className="collapse mordor-expandable-section" id={randomId}>
            {body}
        </div>
    </div>

}